import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { get, pipe, map } from "lodash/fp";
import Layout from "../../layouts";
import SEO from "../../components/SEO";
import { H2, H4 } from "../../components/Type";
import Markdown from "../../components/Markdown";
import { Container, Section } from "../../components/LayoutUtils";
import { IMAGE_POSITIONS } from "../../utils/imagePosition";
import IntroHeader from "../../components/IntroHeader";
import PriceList from "../../components/PriceList";
import MiniGallery from "../../components/MiniGallery";
import ServiceProps from "../../proptypes/ServiceProps";

const Service = ({ data: { service, allServices } }) => (
  <Layout>
    <SEO title={service.title} description={service.description.excerpt.text} />
    <IntroHeader
      imagePosition={IMAGE_POSITIONS.BOTTOM}
      introHeaderTitle={service.title}
      introHeaderSubtitle={service.subtitle}
      heroImage={service.heroImage}
    />
    <Container>
      <Section>
        <div className="row">
          <div className="col-md-6">
            <Markdown source={service.description.description} />
          </div>
          <div className="col-md-5 offset-md-1">
            {service.images ? <MiniGallery images={service.images} /> : null}
            {service.slug === "bespoke-tailoring" ? <PriceList /> : null}
            <H2>Other services</H2>
            {pipe(
              get("edges"),
              map(
                pipe(
                  get("service"),
                  ({ slug, title, subtitle }) => (
                    <div style={{ marginBottom: "1em" }}>
                      <H4>{title}</H4>
                      <Link to={`/services/${slug}`}>{subtitle}</Link>
                    </div>
                  )
                )
              )
            )(allServices)}
          </div>
        </div>
      </Section>
    </Container>
  </Layout>
);

Service.propTypes = {
  data: PropTypes.shape({
    service: ServiceProps,
    allServices: PropTypes.shape({
      edges: PropTypes.arrayOf(ServiceProps)
    })
  }).isRequired
};

export const query = graphql`
  query($slug: String!) {
    service: contentfulService(slug: { eq: $slug }) {
      ...service
    }
    allServices: allContentfulService(filter: { slug: { ne: $slug } }) {
      edges {
        service: node {
          ...service
        }
      }
    }
  }
`;

export default Service;
